export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Banner',
    route: 'banner-index',
    icon: 'FileIcon',
  },
  {
    title: 'User',
    route: 'user-index',
    icon: 'UserIcon',
  },
  {
    title: 'Mata Pelajaran',
    route: 'mapel-index',
    icon: 'BookIcon',
  },
  {
    title: 'Kelas',
    route: 'kelas-index',
    icon: 'ListIcon',
  },
  {
    title: 'Siswa',
    route: 'siswa-index',
    icon: 'UsersIcon',
  },
  {
    title: 'Paket Ujian',
    route: 'jenis-ujian-index',
    icon: 'FolderIcon',
  },
  {
    title: 'Materi',
    icon: 'FileIcon',
    children: [
      {
        title: 'SD',
        route: 'kelas-sd',
      },
      {
        title: 'SMP',
        route: 'kelas-smp',
      },
      {
        title: 'SMA',
        route: 'kelas-sma',
      },
    ],
  },
  {
    title: 'Psikotes',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Riasec',
        route: 'riasec-index',
      },
      {
        title: 'VAK',
        route: 'vak-index',
      },
      // {
      //   title: 'IST',
      //   route: 'ist-index',
      // },
      {
        title: 'Testimoni Psikotes',
        route: 'testimoni-psikotes',
      },
    ],
  },
]
